import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import MobileIcon from "../components/MobileIcon";
import ContactUsForm from "../components/ContactUsForm";
import Footer from "../components/Footer";


// Nitpick Notes:
// 
// - 
// 


const ContactUsPage = () => {
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      {ContactUsForm()}
      {Footer()}
    </div>
  );
};

export default ContactUsPage;
