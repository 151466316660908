import React from "react";
// import { useDropzone } from "react-dropzone";
// import resources from "../resources";
const ResumeUploadForm = () => {
  // const { getRootProps, getInputProps, isDragActive } = useDropzone();

  return (
    <div
      id="resumeUploadFormDiv"
      className="shadow-md rounded px-8 pt-6 pb-8 mb-4 tablet:w-2/3 mx-auto"
    >
      <form
        action="https://formspree.io/f/xjvdlqwl"
        method="POST"
        className="space-y-4"
        encType="multipart/form-data"
      >
        <h1 className="text-md tablet:text-lg font-bold mb-4 text-accent1 text-center font-fira">
          Upload Your Resume
        </h1>
        <div className="">
          <label
            htmlFor="name"
            className="block text-sm font-bold mb-2 text-txtColor1 font-fira"
          >
            Name:
          </label>
          <input
            type="text"
            id="resumeName"
            name="name"
            required
            className="bg-transparent shadow-md shadow-accent2 appearance-none rounded w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline font-fira"
          />
        </div>
        <div className="">
          <label
            htmlFor="email"
            className="block text-txtColor1 text-sm font-fira mb-2 font-bold"
          >
            Email:
          </label>
          <input
            type="email"
            id="resumeEmail"
            name="_replyto"
            className="bg-transparent shadow-md shadow-accent2 appearance-none rounded w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline font-fira"
          />
        </div>{" "}
        <div className="">
          <label
            htmlFor="resume"
            className="block text-txtColor1 text-sm font-bold mb-2 font-fira"
          >
            Resume:
          </label>
          <input
            type="file"
            id="resumeFile"
            name="resume"
            accept=".docx, .pdf"
            className="bg-transparent shadow-md shadow-accent2 appearance-none w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline rounded-md font-fira"
          />
        </div>
        {/* <div
          {...getRootProps()}
          className="flex flex-col items-center px-4 py-6 shadow-accent2 text-txtColor3 bg-accent1 hover:bg-txtColor3 hover:text-accent1 duration-500 rounded-lg shadow-lg tracking-wide uppercase  cursor-pointer hover:bg-blue border-none w-full tablet:w-1/2 mx-auto"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <p className="hidden tablet:block mb-2">Drag and Drop your Resume Here </p>
          )}
          <img className=" w-28 tablet:w-36" alt='' src={resources.icons.upload}/>
          <p className="hidden tablet:block mt-2">or</p>
          <button type="button" className="mt-1">
            Click to Choose File
          </button>
        </div>{" "} */}
        <div>
          <input
            type="submit"
            value="Upload"
            className="bg-accent1 hover:bg-accent2 text-txtColor1 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full tablet:w-auto font-fira"
          />
        </div>
      </form>
    </div>
  );
};
export default ResumeUploadForm;
