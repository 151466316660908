import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";

const ErrorPage = () => {
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      <div className="h-5"></div>
      <div className="font-tektur font-bold w-full text-center text-txtColor3 text-md desktop:text-lg my-5 tracking-widest">
        Error (404): Page not Found
      </div>
      <div className="h-5"></div>
      {Footer()}
    </div>
  );
};

export default ErrorPage;
