// import logo from './logo.svg';
// testing
import HomePage from './pages/home';
import AboutPage from './pages/about';
import ContactUsPage from './pages/contactUs';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from './pages/404';

function App() {
  document.title = "Binary Artisan";

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={HomePage()}>
          </Route>
          <Route path="/home" element={HomePage()}>
          </Route>
          <Route path="/about" element={AboutPage()}>
          </Route>
          <Route path="/contact" element={ContactUsPage()}>
          </Route>
          <Route path="/*" element={<ErrorPage />}>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
