import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import resources from "../resources";
import TeamSlideshow from "../components/TeamSlideshow";
import MissionStatement from "../components/MissionStatement";
import CompanyStory from "../components/CompanyStory";
import ResumeUploadForm from "../components/ResumeUploadForm";

const { dHilleke, sSliman, jlc, harrisonWhitaker } = resources.images;

// Nitpick Notes:
//
//

const staffMembers = [
  {
    name: "James Craig",
    title: "Chief Executive Officer",
    image: jlc,
    email: "James@binaryArtisan.com",
  },
  {
    name: "Patrick Beasley",
    title: "Director of Media and Client Relations",
    // image: 'PWB',
    email: "Patrick@binaryArtisan.com",
  },
  {
    name: "Harrison Whitaker",
    title: "Chief Financial Officer",
    image: harrisonWhitaker,
    email: "Harrison@binaryArtisan.com",
  },
  {
    name: "David Hilleke",
    title: "Developer",
    image: dHilleke,
  },
  {
    name: "Sam Sliman",
    title: "Developer",
    image: sSliman,
  },
];

const AboutPage = () => {
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      <div className="h-5"></div>
      {MissionStatement()}
      {TeamSlideshow(staffMembers)}
      {CompanyStory()}
      <div className="mt-8 block mx-auto w-10/12">
        <p className="text-center text-lg tablet:text-xxl text-txtColor3 font-bold font-sharetech">
          Want to Join Our Team?
        </p>
        {ResumeUploadForm()}
      </div>

      {Footer()}
    </div>
  );
};

export default AboutPage;
