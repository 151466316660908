import React from "react";

const slogan = 'Building High Tech Solutions for Small Businesses'
// todo: break width at with 620px-->1000px
// idea: multi stage transition:
  //  0-33% in 2 seconds; 33-100% in 1 second
const SloganAnimation = () => {
  // expand and grow text size from the middle into a blank space 
  return (
    <div className="font-tektur font-bold w-full text-center text-txtColor3 text-md desktop:text-lg my-5 tracking-widest">
      <p className="animate-scale-in">{slogan}</p>
    </div>)
};

export default SloganAnimation