import React, { useState } from "react";
import contactUsIcon from "../resources/icons/contact-video-call-svgrepo-com.svg";
import consultationIcon from "../resources/icons/conversation-confer-consult-speak-svgrepo-com.svg";
import projectPlanningIcon from "../resources/icons/planning-svgrepo-com.svg";
import constructionIcon from "../resources/icons/industry-worker-with-cap-protection-and-a-laptop-svgrepo-com.svg";
import maintainIcon from "../resources/icons/computer-setting-outline-interface-symbol-in-a-circle-svgrepo-com.svg";
// Reach Out
// Free Consultation
// Project Planning and Budgeting
// Kickoff
// Maintain and Update your Project

const clientSteps = [
  {
    label: "Reach Out",
    description:
      "Reach out to our team of experts through any of the resources found on our contact page.",
    icon: contactUsIcon,
    link: "./contact",
  },
  {
    label: "Free Consultation",
    description:
      "Our team will meet with you to discuss your unique needs and help determine effective digital solutions to provide growth to your business.",
    icon: consultationIcon,
    link: "./portfolio",
  },
  {
    label: "Project Planning",
    description:
      "After determining the appropriate solutions that fit your needs, our team will create a project development plan to help explain the process.",
    icon: projectPlanningIcon,
    link: "",
  },
  {
    label: "Development",
    description:
      "After executing the project plan, our team will diligently work to build your vision.",
    icon: constructionIcon,
    link: "",
  },
  {
    label: "Maintain",
    description:
      "After building your unique solution, our team of developers will continue to monitor, support, and update your solutions as needed.",
    icon: maintainIcon,
    link: "",
  },
];

// Todo: add an animation so that step 1 is highlighted for a moment, then the description pops up, then its highlighted twice, and the description goes away. This automatically infers the usage of the element.

const ClientSteps = () => {
  const [currentDescription, setCurrentDescription] = useState(clientSteps[0].description);

  const handleDoubleClick = () => {
    setCurrentDescription(null);
  };

  return (
    <div className="mb-12">
      <p className="text-center  text-md desktop:text-lg text-txtColor1 font-bold font-fira tracking-tight">Need a Website or Backend Service?</p>
      <p className="text-center text-sm text-txtColor1 font-fira">Well, its just this easy!</p>
      <div className="tablet:item-center justify-around laptop:flex laptop:justify-around items-center flex-row ">
        {clientSteps.map(({ label, description, icon, link }, i) => {
          return (
            <div
              onClick={() => {
                setCurrentDescription(description);
              }}
              onDoubleClick={handleDoubleClick}
              key={label}
              className={`my-16 laptop:mb-0 tablet:my-10 mx-auto w-10/12 tablet:w-1/2 justify-center border-2 border-ivory tablet:border-t-solid rounded-xl  laptop:rounded-lg laptop:w-1/5 align-top laptop:border-none laptop:shadow-accent2 laptop:shadow-2x1  transition duration-500 select-none ${
                currentDescription === description
                  ? "bg-accent1"
                  : "bg-background1 hover:shadow-black shadow-md"
              }`}
            >
              <p className={`text-md font-bold text-center font-sharetech ${
                currentDescription===description
                  ? "text-txtColor1"
                  : "text-txtColor3"
              }`}>Step {i + 1}</p>
              <p className={`text-md font-bold text-center font-sharetech ${
                currentDescription===description
                  ? "text-txtColor1"
                  : "text-txtColor3"
              }`}>{label}</p>
              <img className="w-36 block mx-auto my-5" alt="" src={icon} />
              {currentDescription === description && (
                <p className="mx-5 mb-2 laptop:mt-2 laptop:hidden text-center text-sm select-none font-sharetech text-txtColor1">
                  {currentDescription}
                </p>
              )}
            </div>
          );
        })}
      </div>
      {currentDescription && (
        <p className=" bg-accent1 mx-5 tablet:mx-0 hidden text-center w-full laptop:block tablet:text-md py-5 select-none text-txtColor1 shadow-lg shadow-accent2 font-sharetech">
          {currentDescription}
        </p>
      )}
    </div>
  );
};

export default ClientSteps;
