import React from "react";
const CompanyStory = () => (
  <div className="w-full tablet:w-10/12 mx-auto mb-8 shadow-sm rounded-lg p-8 ">
    <h2 className="text-xl font-bold text-center text-txtColor3 font-sharetech">Company Story</h2>
    <p className="text-center text-sm tablet:text-sm text-txtColor1 font-fira">
    Founded in 2022, Binary Artisan, LLC was established by Patrick Wayne Beasley Jr., James Landon Craig, and Harrison Whitaker. Patrick leads Design, Marketing, and Client Relations, James serves as the CEO, and Harrison handles finances as CFO. Together, our tight-knit team delivers custom digital solutions for small businesses and contractors, driven by our passion to empower clients with innovative technology, ensuring they thrive in the digital era.
    </p>
  </div>
);

export default CompanyStory;