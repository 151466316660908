import React from "react";
const MissionStatement = () => (
  <div className="w-full tablet:w-10/12 mx-auto mb-8 shadow-sm rounded-lg p-8 ">
    <h2 className="text-xl font-bold text-center text-txtColor3 font-sharetech ">
      Our Mission
    </h2>
    <p className=" text-sm text-txtColor1 text-center font-fira">
    At Binary Artisan, LLC, we aim to use technology with integrity and ingenuity to craft superior digital experiences, empowering small businesses and contractors. We prioritize enduring client relationships, delivering custom solutions that surpass expectations, and promoting open communication. As your digital bridge, we provide bespoke websites and backend tools that amplify online presence, helping businesses thrive in a dynamic landscape.
    </p>
  </div>
);

export default MissionStatement