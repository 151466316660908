import React from "react";
const ContactUsForm = () => {
  return (
    <div
      id="contactUsFormDiv"
      className="shadow-sm rounded px-8 pt-6 pb-8 mb-4 tablet:w-2/3 mx-auto mt-6"
    >
      <form
        action="https://formspree.io/f/mnqywkdl"
        method="POST"
        className="space-y-4"
      >
        <h1 className="text-xl text-txtColor3 font-bold mb-4 font-sharetech ">Contact Us</h1>
        <div className="">
          <label
            htmlFor="subject"
            className="block text-txtColor1 text-sm font-bold mb-2 font-fira tracking-tighter"
          >
            Subject:
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            className="bg-transparent shadow-md shadow-accent2 appearance-none  rounded w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline font-fira"
          />
        </div>
        <div className="">
          <label
            htmlFor="name"
            className="block text-txtColor1 text-sm font-bold mb-2 font-fira"
          >
            Name:
          </label>
          <input
            type="text"
            id="reviewName"
            name="name"
            required
            className="bg-transparent shadow-md shadow-accent2 appearance-none  rounded w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline font-fira"
          />
        </div>
        <div className="">
          <label
            htmlFor="phoneNumber"
            className="block text-txtColor1 text-sm font-bold mb-2 font-fira"
          >
            Phone:
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            required
            className="bg-transparent shadow-md shadow-accent2 appearance-none  rounded w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline font-fira"
          />
        </div>
        <div className="">
          <label
            htmlFor="email"
            className="block text-txtColor1 text-sm font-bold mb-2 font-fira"
          >
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="_replyto"
            className="bg-transparent shadow-md shadow-accent2 appearance-none  rounded w-full py-2 px-3 text-txtColor1 leading-tight focus:outline-none focus:shadow-outline font-fira"
          />
        </div>
        <div>
          <textarea
            name="note"
            defaultValue="Comments or Notes"
            className="bg-transparent shadow-lg appearance-none rounded w-full py-2 px-3 text-txtColor1 leading-tight h-24 shadow-accent2 font-fira"
          ></textarea>
        </div>
        <div>
          <input
            type="submit"
            value="Submit"
            className="bg-accent1 hover:bg-accent2 text-txtColor1 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full tablet:w-auto font-fira"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm