import React, { useState, useEffect } from "react";
import resources from "../resources";

const { next, back } = resources.icons;

const TeamSlideshow = (staffMembers) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const totalMembers = staffMembers.length;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalMembers);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + totalMembers) % totalMembers
    );
  };

  const currentStaffMember = staffMembers[currentIndex];

  useEffect(() => {
    staffMembers.forEach((member) => {
      const img = new Image();
      img.src = member.image;
    });
  });

  return (
    <div className=" w-full py-25">
      <p className="text-center text-xxl font-bold text-txtColor3 font-sharetech">The Team</p>
      <div className="flex items-center justify-center bg-gray-900 py-6">
        <button
          className="hidden tablet:flex bg-txtColor2 rounded-full shadow-lg p-2 text-gray-900 focus:outline-none mx-5"
          onClick={handlePrevious}
        >
          <img className="w-14" alt="" src={back} />
        </button>

        <div className="tablet:flex items-center justify-center space-x-4 select-none w-full tablet:w-1/2 mb-2" onClick={handleNext}>
          { currentStaffMember.image && <img
            className="mx-auto mb-5 tablet:mx-0 tablet:block rounded-full  w-48 h-48 tablet:w-36 tablet:h-36 object-cover"
            src={currentStaffMember.image}
            alt=''
          />}

          <div>
            <h2 className="w-full text-xs tablet:text-lg text-txtColor3 text-center font-fira">
              {currentStaffMember.name}
            </h2>
            <p className="w-full text-sm tablet:text-2xl font-bold text-txtColor1 text-center font-fira">
              {currentStaffMember.title}
            </p>
            {currentStaffMember.description && (
              <p className="w-full text-base text-txtColor1 font-fira">
                {currentStaffMember.description}
              </p>
            )}
            {currentStaffMember.email && (
              <div className="w-6/12 mx-auto tablet:w-10/12">
                <a href={"mailto:"+currentStaffMember.email} className="text-base text-txtColor1 text-xs text-center block font-fira" onClick={handlePrevious}>
                {currentStaffMember.email}
                </a>
              </div>
            )}
          </div>
        </div>

        <button
          className="hidden tablet:flex bg-txtColor2 rounded-full shadow-lg p-2 text-gray-900 focus:outline-none mx-5"
          onClick={handleNext}
        >
          <img className="w-14" alt="" src={next} />
        </button>
      </div>
          <p className="text-center text-sm text-txtColor2 -mt-8">{currentIndex+1} of {staffMembers.length}</p>
    </div>
  );
};

export default TeamSlideshow;
