import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import SloganAnimation from "../components/SloganAnimation";
import ClientSteps from "../components/ClientSteps";


// Nitpick Notes:
// 
// -Fonts
// 

const HomePage = ()=>{
  return (<div>
    {MobileNavbar()}{NavBar()}{MobileIcon()}
    {SloganAnimation()}
    {ClientSteps()}
    {Footer()}
  </div>)
};

export default HomePage